@font-face {
  font-family: "Cags_Hetonal";
  src: local("Cags_Hetonal"),
    url("./assets/fonts/Cags_Hetonal.otf") format("opentype");
  font-weight: normal;
}


/* Provide sufficient contrast against white background */
#root {
  position: relative;
  font-family: "monserrat";
}
a {
  color: #0366d6;
}

.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

 .MuiOutlinedInput-notchedOutline {
    border-color: transparent !important;
    color: #000 !important;
    background-color: #fff;
} 
.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
 color: #000 !important;

}
.MuiOutlinedInput-notchedOutline {
  color: #000 !important;

}
#file-upload-button {
  display: none !important;
}
